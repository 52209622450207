import type { RouterConfig } from '@nuxt/schema'
import { createWebHistory } from 'vue-router'

export default <RouterConfig>{
  history: (base) => (import.meta.client ? createWebHistory(base) : null),
  scrollBehavior: (to) => {
    if (to.hash) return { el: to.hash, behavior: 'smooth' }
    return { top: 0, behavior: 'smooth' }
  },
  routes: (_routes) => [
    {
      name: 'index',
      path: '/',
      meta: { layout: 'site' },
      component: () => import('~/pages/site/index.vue'),
    },
    {
      name: 'entrar',
      path: '/entrar',
      meta: { layout: 'authentication', middleware: 'auth' },
      component: () => import('~/pages/authentication/login.vue'),
    },
    {
      name: 'divulgar espaco',
      path: '/divulgar-espaco',
      meta: { layout: 'site' },
      component: () => import('~/pages/site/landings/owner-landing.vue'),
    },
    {
      name: 'blog',
      path: '/blog',
      meta: { layout: 'site' },
      component: () => import('~/pages/blog/index.vue'),
    },
    {
      name: 'blog-article',
      path: '/blog/:slug',
      meta: { layout: 'site' },
      component: () => import('~/pages/blog/article.vue'),
    },
    {
      name: 'registrar',
      path: '/registrar',
      meta: { layout: 'authentication', middleware: 'auth' },
      component: () => import('~/pages/authentication/register.vue'),
    },
    {
      name: 'registrar dono',
      path: '/registrar-donoespaco',
      meta: { layout: 'authentication', middleware: 'auth' },
      component: () => import('~/pages/authentication/register-owner.vue'),
    },
    {
      name: 'propriedade-espaco',
      path: '/espacos/:id/reivindicar-propriedade',
      meta: { layout: 'authentication' },
      component: () =>
        import('~/pages/authentication/space-claim-ownership.vue'),
    },
    {
      name: 'recuperar senha',
      path: '/recuperar-senha',
      meta: { layout: 'authentication' },
      component: () => import('~/pages/authentication/password-recovery.vue'),
    },
    {
      name: 'nova senha',
      path: '/nova-senha',
      meta: { layout: 'authentication' },
      component: () => import('~/pages/authentication/new-password.vue'),
    },
    {
      name: 'contato',
      path: '/contato',
      meta: { layout: 'site' },
      component: () => import('~/pages/site/contact.vue'),
    },
    {
      name: 'privacidade',
      path: '/privacidade',
      meta: { layout: 'site' },
      component: () => import('~/pages/site/privacy.vue'),
    },
    {
      name: 'exclusao dados facebook',
      path: '/exclusao-dados-facebook',
      meta: { layout: 'site' },
      component: () => import('~/pages/site/fbdatadeletion.vue'),
    },
    {
      name: 'search',
      path: '/espacos',
      meta: { layout: 'site' },
      component: () => import('~/pages/site/spaces/search.vue'),
      children: [
        {
          name: 'search-city',
          path: '/espacos/:city',
          meta: { layout: 'site' },
          component: () => import('~/pages/site/spaces/search.vue'),
        },
        {
          name: 'search-city-neighborhood',
          path: '/espacos/:city/:neighborhood',
          meta: { layout: 'site' },
          component: () => import('~/pages/site/spaces/search.vue'),
        },
      ],
    },
    {
      name: 'space',
      path: '/espacos/:city/:neighborhood/:name--:id(\\d+)',
      meta: { layout: 'site' },
      component: () => import('~/pages/site/spaces/details.vue'),
    },
    {
      name: 'minha conta',
      path: '/minha-conta',
      meta: { layout: 'dashboard', middleware: 'auth' },
      component: () => import('~/pages/dashboard/index.vue'),
    },
    {
      name: 'favoritos',
      path: '/minha-conta/favoritos',
      meta: { layout: 'dashboard', middleware: 'auth' },
      component: () => import('~/pages/dashboard/favorites.vue'),
    },
    {
      name: 'avaliacoes',
      path: '/minha-conta/avaliacoes',
      meta: { layout: 'dashboard', middleware: 'auth' },
      component: () => import('~/pages/dashboard/reviews.vue'),
    },
    {
      name: 'orcamentos',
      path: '/minha-conta/orcamentos',
      meta: { layout: 'dashboard', middleware: 'auth' },
      component: () => import('~/pages/dashboard/quotes.vue'),
    },
    {
      name: 'configuracoes',
      path: '/minha-conta/configuracoes',
      meta: { layout: 'dashboard', middleware: 'auth' },
      component: () => import('~/pages/dashboard/settings.vue'),
    },
    {
      name: 'meu espaco',
      path: '/minha-conta/meu-espaco',
      meta: { layout: 'dashboard', middleware: 'auth' },
      component: () => import('~/pages/dashboard/spaces/new.vue'),
    },
    {
      name: 'espacos pendentes',
      path: '/minha-conta/espacos/pendentes',
      meta: { layout: 'dashboard', middleware: 'auth' },
      component: () =>
        import('~/components/dashboard/admin/AdminPendingSpaces.vue'),
    },
  ],
}
