import { defineStore } from 'pinia'

// @ts-ignore
export const useLoadingStore = defineStore('loading', {
  state: () => ({
    isFetchLoading: false,
  }),
  getters: {
    getIsFetchLoading(): boolean {
      return this.isFetchLoading
    },
  },
  actions: {
    setFetchLoading(isLoading: boolean) {
      this.isFetchLoading = isLoading
    },
  },
})
