<script setup lang="ts">
  import AuthenticationService from '~/services/AuthenticationService'
  import MenuNavigation from '~/components/menus/site/MenuNavigation.vue'
  import UserNotLoggedMenu from '~/components/menus/site/UserNotLoggedMenu.vue'
  import UserLoggedInMenu from '~/components/menus/site/UserLoggedInMenu.vue'
  import LogoMenu from '~/components/menus/site/LogoMenu.vue'
  import MobileSlideOver from '~/components/menus/site/MobileSlideOver.vue'
  import UserRole from '~/models/enums/UserRole'

  const isUserLoggedIn = ref(false)
  const signedUser: any = ref({})

  const navigation = [
    { name: 'Espaços', href: '/espacos' },
    /* { name: 'Fornecedores', href: '/fornecedores' }, */
    { name: 'Divulgue seu espaço', href: '/divulgar-espaco' },
    /* { name: 'Divulgue seu serviço', href: '/divulgar-servico' }, */
    { name: 'Fale conosco', href: '/contato' },
    { name: 'Blog', href: '/blog' },
  ]

  onMounted(async () => {
    await nextTick(() => {
      isUserLoggedIn.value = AuthenticationService.isAuthenticated()
      signedUser.value = AuthenticationService.getLoggedUser()
    })
  })
</script>

<template>
  <header class="inset-x-0 z-10">
    <!-- DESKTOP -->
    <nav
      aria-label="desktopMenu"
      class="mx-auto hidden max-w-7xl items-center p-6 md:flex md:px-8">
      <div class="mr-6 flex border-r pr-6">
        <LogoMenu />
      </div>
      <div class="flex gap-x-6">
        <MenuNavigation :navigation="navigation" />
      </div>
      <div
        v-if="!isUserLoggedIn"
        class="flex flex-1 justify-end">
        <UserNotLoggedMenu />
      </div>
      <div
        v-else
        class="flex flex-1 justify-end">
        <p
          v-if="signedUser.role === UserRole.OWNER"
          class="my-1 mr-4 flex w-2/6 items-center justify-center rounded-md bg-gray-50 text-xs font-medium uppercase tracking-wide text-green-700">
          <span>Plano Gratuito</span>
        </p>
        <UserLoggedInMenu />
      </div>
    </nav>

    <!-- MOBILE   -->
    <nav class="mx-auto flex items-center justify-between p-3 md:hidden">
      <MobileSlideOver
        :navigation="navigation"
        class="ml-2" />
      <div class="flex">
        <LogoMenu />
      </div>
      <div>&nbsp;</div>
    </nav>
  </header>
</template>
