<script setup lang="ts">
  import {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue'
  import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
  import type { PropType } from 'vue'
  import LogoMenu from '~/components/menus/site/LogoMenu.vue'
  import AuthenticationService from '~/services/AuthenticationService'
  import UserNotLoggedMenu from '~/components/menus/site/UserNotLoggedMenu.vue'
  import MobileUserLoggedInMenuItems from '~/components/menus/site/MobileUserLoggedInMenuItems.vue'

  defineProps({
    navigation: {
      type: Array as PropType<
        {
          name: string
          href: string
        }[]
      >,
      required: true,
    },
  })

  const open = ref(false)
  const isUserLoggedIn = ref(false)
  const signedUser: any = ref({})

  onMounted(async () => {
    await nextTick(() => {
      isUserLoggedIn.value = AuthenticationService.isAuthenticated()
      signedUser.value = AuthenticationService.getLoggedUser()
    })
  })

  const toggleSlideOver = () => {
    open.value = !open.value
  }
</script>

<template>
  <div>
    <div class="flex">
      <Bars3Icon
        class="h-7 w-7 cursor-pointer stroke-1 text-gray-700"
        aria-hidden="true"
        @click="toggleSlideOver" />
    </div>
    <TransitionRoot
      as="template"
      :show="open">
      <Dialog
        as="div"
        class="relative z-50">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity sm:bg-transparent" />
        </TransitionChild>
        <div class="fixed inset-0" />

        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div
              class="fixed inset-y-0 left-0 flex max-w-full pr-20"
              @click="toggleSlideOver">
              <TransitionChild
                as="template"
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enter-from="-translate-x-full"
                enter-to="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leave-from="translate-x-0"
                leave-to="-translate-x-full">
                <DialogPanel class="pointer-events-auto w-screen max-w-md">
                  <div
                    class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div class="mb-2 border-b bg-gray-50 pb-2 pr-4">
                      <div class="flex items-center justify-end py-1">
                        <button
                          type="button"
                          class="rounded-md text-gray-700 hover:text-gray-900 focus:outline-none"
                          @click="toggleSlideOver">
                          <span class="sr-only">Fechar menu</span>
                          <XMarkIcon
                            class="h-7 w-7 stroke-1 text-gray-700"
                            aria-hidden="true" />
                        </button>
                      </div>
                      <div class="mb-4 flex items-center justify-center">
                        <LogoMenu class="py-2 pl-4" />
                      </div>
                    </div>
                    <div
                      class="my-4 flex items-center justify-center border-b px-4 pb-4">
                      <ul>
                        <li
                          v-for="item in navigation"
                          :key="item"
                          class="">
                          <NuxtLink
                            :to="item.href"
                            class="block py-2 text-center text-sm font-bold tracking-wide text-gray-700">
                            {{ item.name }}
                          </NuxtLink>
                        </li>
                        <div
                          v-if="!isUserLoggedIn"
                          class="flex justify-center">
                          <UserNotLoggedMenu />
                        </div>
                      </ul>
                    </div>
                    <div class="my-4">
                      <div v-if="isUserLoggedIn">
                        <MobileUserLoggedInMenuItems />
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
