const GoogleSignIn = {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
GoogleSignIn.install = function install(Vue, options) {
  ;(function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    const js = d.createElement(s)
    js.id = id
    js.src = options.googlePlatformInUrl
    js.async = true
    js.defer = true
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'google-jsplataform')
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(GoogleSignIn, {
    googlePlatformInUrl: 'https://apis.google.com/js/platform.js',
  })
})
