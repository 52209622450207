import type { Ref } from 'vue'
import { FetchError } from 'ofetch'
import { RestService } from '~/services/RestService'
import Endpoints from '~/models/constants/Endpoints'
import LoginOrigin from '~/models/enums/LoginOrigin'
import CookieService from '~/services/CookieService'
import UserRole from '~/models/enums/UserRole'
import GoogleService from '~/services/GoogleService'
import type { GoogleProfile } from '~/models/GoogleProfile'
import type { User } from '~/models/User'
import FacebookService from '~/services/FacebookService'
import type { FacebookProfile } from '~/models/FacebookProfile'
import { useTokenHelper } from '~/composables/useTokenHelper'

class AuthenticationService extends RestService {
  public async loginWithEmail(email: string, password: string) {
    await this.login({
      username: email,
      password,
      origin: LoginOrigin.APPLICATION,
    })
  }

  public async loginWithGoogle(role: UserRole) {
    const googleProfile: GoogleProfile = await GoogleService.getUserProfile()
    const user: Partial<User> = {
      name: googleProfile.name,
      username: googleProfile.username,
      socialProvidedToken: googleProfile.socialProvidedToken,
      origin: googleProfile.origin,
      password: '',
      role,
    }
    await this.login(user)
  }

  public async loginWithFacebook(role: UserRole) {
    const facebookProfile: FacebookProfile =
      await FacebookService.getUserProfile()
    const user: Partial<User> = {
      name: facebookProfile.name,
      username: facebookProfile.username,
      socialProvidedToken: facebookProfile.socialProvidedToken,
      origin: facebookProfile.origin,
      password: '',
      role,
    }
    await this.login(user)
  }

  async logout() {
    const route = useRoute()
    await this.postSecured(
      `${Endpoints.AUTHENTICATION}/logout`,
      CookieService.getCookie('token'),
      {
        bodyType: 'text',
      }
    )
    CookieService.deleteCookie('token')
    if (route.fullPath !== '/') {
      window.location.href = '/'
    } else window.location.reload()
  }

  public isAuthenticated(): boolean {
    return !!CookieService.getCookie('token')
  }

  public getLoggedUser(): Partial<User> {
    if (process.client) {
      if (!this.isAuthenticated()) return {}
      return useTokenHelper().getUserFromToken(CookieService.getCookie('token'))
    }
    return {}
  }

  public async sendPasswordRecoveryMail(email: string) {
    await this.post(
      `${Endpoints.AUTHENTICATION}/password-recovery`,
      {
        email,
        recoverPasswordCallbackUrl: `${
          useRuntimeConfig().public.appBaseUrl
        }/nova-senha`,
      },
      {}
    )
  }

  public async updateUserPassword(
    password: string | (string | null)[],
    token: string | (string | null)[]
  ): Promise<void> {
    await this.post(
      `${Endpoints.AUTHENTICATION}/new-password`,
      {
        password,
        token,
      },
      {}
    )
  }

  private async login(user: Partial<User>): Promise<void> {
    const response: { data: Ref<any>; error: Ref<FetchError<any> | null> } =
      await this.post(`${Endpoints.AUTHENTICATION}/login`, user, {})

    // @ts-ignore
    if (response.error.value !== null) {
      // @ts-ignore
      throw createError(response.error.value.data)
    } else {
      // @ts-ignore
      const loggedUser = response.data.value
      CookieService.setCookie('token', loggedUser.token, 7)
      await useRouter().push({ name: 'minha conta' })
    }
  }
}

export default new AuthenticationService()
