export default class Urls {
  public static readonly FACEBOOK_SHARE: string =
    'https://www.facebook.com/sharer.php?u='

  public static readonly TWITTER_SHARE: string =
    'https://twitter.com/intent/tweet?url='

  public static readonly WHATSAPP_SHARE: string =
    'https://api.whatsapp.com/send?text='

  public static readonly API_URL: string = 'https://api.emfesta.com.br'

  // public static readonly API_URL: string = 'http://localhost:8090'
}
