class CookieService {
  setCookie(name: string, value: string, daysToLive: number): void {
    const d = new Date()
    d.setTime(d.getTime() + daysToLive * 24 * 60 * 60 * 1000)
    const expires = 'expires=' + d.toUTCString()
    document.cookie = name + '=' + value + ';' + expires + ';path=/'
  }

  getCookie(key: string): string {
    const name = key + '='
    const ca = document.cookie.split(';')
    for (const element of ca) {
      let c = element
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  deleteCookie(key: string) {
    document.cookie = key + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  }
}

export default new CookieService()
