<script setup lang="ts">
  import { MenuItem, MenuItems } from '@headlessui/vue'
  import { ArrowLeftStartOnRectangleIcon } from '@heroicons/vue/24/outline'
  import AuthenticationService from '~/services/AuthenticationService'
  import DashboardMenuService from '~/services/DashboardMenuService'
  import UserRole from '~/models/enums/UserRole'
  import type { NavigationMenuItem } from '~/models/NavigationMenuItem'
  import type { User } from '~/models/User'

  const navigation = ref<NavigationMenuItem[]>([])
  const loggedUser: Partial<User> = AuthenticationService.getLoggedUser()

  const logout = () => {
    AuthenticationService.logout()
  }

  onMounted(() => {
    navigation.value = DashboardMenuService.getStaticMenuItens(
      loggedUser.role ?? UserRole.USER
    )
  })
</script>
<template>
  <MenuItems
    class="absolute right-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
    <div class="border-b bg-gray-50 py-4">
      <p class="text-center text-sm font-semibold">{{ loggedUser.name }}</p>
      <p
        v-if="loggedUser.role === UserRole.OWNER"
        class="cursor-pointer text-center">
        <a class="text-xs font-light hover:text-orange-400 hover:underline"
          >Ver meu espaço</a
        >
      </p>
    </div>
    <div class="border-b py-1">
      <MenuItem
        v-for="item in navigation"
        :key="item.slug"
        v-slot="{ active, close }">
        <a
          :href="item.href"
          :class="[
            active ? 'text-orange-400' : 'text-gray-700',
            'block w-full cursor-pointer px-4 py-3 text-center text-sm font-semibold',
          ]"
          @click="close">
          <component
            :is="item.icon"
            class="mr-2 inline-block h-6 w-6 stroke-1"
            aria-hidden="true" />
          <span class="text-sm font-light">{{ item.name }}</span></a
        >
      </MenuItem>
    </div>
    <div class="flex py-4">
      <MenuItem
        v-slot="{ active }"
        class="flex items-center justify-center">
        <a
          :class="[
            active ? 'text-orange-400' : 'text-gray-700',
            'block w-full cursor-pointer px-4 py-2 text-left text-sm font-semibold',
          ]"
          @click.prevent="logout">
          <ArrowLeftStartOnRectangleIcon
            class="mr-1 inline-block h-6 w-6 stroke-1 text-red-500"
            aria-hidden="true" />
          <span class="font-light text-red-500">Sair</span></a
        >
      </MenuItem>
    </div>
  </MenuItems>
</template>
