<script setup lang="ts">
  import type { NuxtError } from '#app'
  import SiteHeader from '~/components/site/SiteHeader.vue'

  defineProps({
    error: {
      type: Object as () => NuxtError,
      default: () => {},
    },
  })

  const handleError = () => clearError({ redirect: '/' })

  const translateErrorCode = (statusCode: number) => {
    switch (statusCode) {
      case 400:
        return 'Parece que ocorreu um erro na sua solicitação'
      case 401:
        return 'Acesso não autorizado'
      case 403:
        return 'Recurso não permitido para o usuário atual'
      case 404:
        return 'Página não encontrada'
      case 500:
        return 'Erro interno do servidor'
      case 503:
        return 'Serviço indisponível'
      default:
        return 'Erro'
    }
  }
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <SiteHeader />
    <main
      class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div class="text-center">
        <p class="text-base font-semibold text-orange-500">
          {{ error.statusCode }}
        </p>
        <h1
          class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {{ translateErrorCode(error.statusCode) }}
        </h1>
        <p class="mt-6 text-base leading-7 text-gray-600">
          {{ error.message }}
        </p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <a
            class="cursor-pointer rounded-md bg-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="handleError"
            >Voltar para o início</a
          >
          <a
            href="/contato"
            class="text-sm font-semibold text-gray-900"
            >Entrar em contato <span aria-hidden="true">&rarr;</span></a
          >
        </div>
      </div>
    </main>
    <LazySiteNewsletter />
    <LazySiteFooter />
  </div>
</template>
