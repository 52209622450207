import type { Ref } from 'vue'
import type { FetchContext, FetchError } from 'ofetch'
import CookieService from '~/services/CookieService'
import { useLoadingStore } from '~/stores/loadingStore'
import Urls from '~/models/constants/Urls'

export abstract class RestService {
  public async find(
    resource: string,
    config: any,
    skipLoading: boolean = false
  ): Promise<{
    data: Ref<any>
    error: Ref<FetchError<any> | null>
  }> {
    const { data, error } = await useFetch(resource, {
      method: 'GET',
      baseURL: Urls.API_URL,
      ...config,
      onRequest(): Promise<void> | void {
        if (!skipLoading) useLoadingStore().setFetchLoading(true)
      },
      onResponse(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      onResponseError(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
    })
    // @ts-ignore
    return { data, error }
  }

  public async findSecured(
    resource: string,
    config: any
  ): Promise<{ data: Ref<any>; error: Ref<FetchError<any> | null> }> {
    const { data, error } = await useFetch(resource, {
      method: 'GET',
      baseURL: Urls.API_URL,
      onRequest(context: FetchContext): Promise<void> | void {
        useLoadingStore().setFetchLoading(true)
        context.options.headers = {
          ...context.options.headers,
          Authorization: `Bearer ${CookieService.getCookie('token')}`,
        }
      },
      onResponse(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      onResponseError(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      ...config,
    })
    // @ts-ignore
    return { data, error }
  }

  public async put(
    resource: string,
    body: any,
    config: any
  ): Promise<{ data: Ref<any>; error: Ref<FetchError<any> | null> }> {
    const { data, error } = await useFetch(resource, {
      method: 'PUT',
      body: JSON.stringify({ ...body }),
      baseURL: Urls.API_URL,
      onRequest(context: FetchContext): Promise<void> | void {
        useLoadingStore().setFetchLoading(true)
        context.options.headers = {
          ...context.options.headers,
          Authorization: `Bearer ${CookieService.getCookie('token')}`,
        }
      },
      onResponse(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      onResponseError(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      ...config,
    })
    // @ts-ignore
    return { data, error }
  }

  public async patch(
    resource: string,
    body: any,
    config: any
  ): Promise<{ data: Ref<any>; error: Ref<FetchError<any> | null> }> {
    const { data, error } = await useFetch(resource, {
      method: 'PATCH',
      body: config.bodyType === 'text' ? body : JSON.stringify({ ...body }),
      baseURL: Urls.API_URL,
      onRequest(context: FetchContext): Promise<void> | void {
        useLoadingStore().setFetchLoading(true)
        context.options.headers = {
          ...context.options.headers,
          Authorization: `Bearer ${CookieService.getCookie('token')}`,
        }
      },
      onResponse(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      onResponseError(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      ...config,
    })
    // @ts-ignore
    return { data, error }
  }

  public async delete(
    resource: string,
    config: any
  ): Promise<{ data: Ref<any>; error: Ref<FetchError<any> | null> }> {
    const { data, error } = await useFetch(resource, {
      method: 'DELETE',
      baseURL: Urls.API_URL,
      onRequest(context: FetchContext): Promise<void> | void {
        useLoadingStore().setFetchLoading(true)
        context.options.headers = {
          ...context.options.headers,
          Authorization: `Bearer ${CookieService.getCookie('token')}`,
        }
      },
      onResponse(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      onResponseError(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      ...config,
    })
    // @ts-ignore
    return { data, error }
  }

  public async post(
    resource: string,
    body: any,
    config: any
  ): Promise<{ data: Ref<any>; error: Ref<FetchError<any> | null> }> {
    const { data, error } = await useFetch(resource, {
      method: 'POST',
      body: JSON.stringify({ ...body }),
      baseURL: Urls.API_URL,
      onRequest(): Promise<void> | void {
        useLoadingStore().setFetchLoading(true)
      },
      onResponse(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      onResponseError(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      ...config,
    })
    // @ts-ignore
    return { data, error }
  }

  public async postSecured(
    resource: string,
    body: any,
    config: any
  ): Promise<{ data: Ref<any>; error: Ref<FetchError<any> | null> }> {
    const { data, error } = await useFetch(resource, {
      method: 'POST',
      body: config.bodyType === 'text' ? body : JSON.stringify({ ...body }),
      baseURL: Urls.API_URL,
      onRequest(context: FetchContext): Promise<void> | void {
        useLoadingStore().setFetchLoading(true)
        context.options.headers = {
          ...context.options.headers,
          Authorization: `Bearer ${CookieService.getCookie('token')}`,
        }
      },
      onResponse(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      onResponseError(): Promise<void> | void {
        useLoadingStore().setFetchLoading(false)
      },
      ...config,
    })
    // @ts-ignore
    return { data, error }
  }

  public handleResponse(response: {
    data: Ref<any>
    error: Ref<FetchError<any> | null>
  }): Ref<any> {
    if (response.error.value !== null && response.error.value !== undefined) {
      // @ts-ignore
      throw createError(response.error.value.data)
    } else {
      // @ts-ignore
      return response.data.value
    }
  }
}
