import { default as loginKhXhHTtdOCMeta } from "/app/pages/authentication/login.vue?macro=true";
import { default as new_45passwordf9lhzfOmuWMeta } from "/app/pages/authentication/new-password.vue?macro=true";
import { default as password_45recoverydTRUOQ7SVrMeta } from "/app/pages/authentication/password-recovery.vue?macro=true";
import { default as register_45owners6kwooUqfCMeta } from "/app/pages/authentication/register-owner.vue?macro=true";
import { default as registerNdFBDguLk2Meta } from "/app/pages/authentication/register.vue?macro=true";
import { default as space_45claim_45ownershipOkJjtmzGVlMeta } from "/app/pages/authentication/space-claim-ownership.vue?macro=true";
import { default as articleYEuth1xDUiMeta } from "/app/pages/blog/article.vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as favorites1tsiXPa9KkMeta } from "/app/pages/dashboard/favorites.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as quotesFLFNGcFRQEMeta } from "/app/pages/dashboard/quotes.vue?macro=true";
import { default as reviewsWQ0KYN4sK8Meta } from "/app/pages/dashboard/reviews.vue?macro=true";
import { default as settingsrNnc9GYBsHMeta } from "/app/pages/dashboard/settings.vue?macro=true";
import { default as newRWUrranZg2Meta } from "/app/pages/dashboard/spaces/new.vue?macro=true";
import { default as contactlO9LtnQYADMeta } from "/app/pages/site/contact.vue?macro=true";
import { default as fbdatadeletion9DOsGtIwduMeta } from "/app/pages/site/fbdatadeletion.vue?macro=true";
import { default as index9HKg8bK0kMMeta } from "/app/pages/site/index.vue?macro=true";
import { default as owner_45landing3LoEZILWl6Meta } from "/app/pages/site/landings/owner-landing.vue?macro=true";
import { default as privacyaFPfLh6cHgMeta } from "/app/pages/site/privacy.vue?macro=true";
import { default as detailshBvo07pSj6Meta } from "/app/pages/site/spaces/details.vue?macro=true";
import { default as searchqTPyivrlpUMeta } from "/app/pages/site/spaces/search.vue?macro=true";
export default [
  {
    name: "authentication-login",
    path: "/authentication/login",
    component: () => import("/app/pages/authentication/login.vue")
  },
  {
    name: "authentication-new-password",
    path: "/authentication/new-password",
    component: () => import("/app/pages/authentication/new-password.vue")
  },
  {
    name: "authentication-password-recovery",
    path: "/authentication/password-recovery",
    component: () => import("/app/pages/authentication/password-recovery.vue")
  },
  {
    name: "authentication-register-owner",
    path: "/authentication/register-owner",
    component: () => import("/app/pages/authentication/register-owner.vue")
  },
  {
    name: "authentication-register",
    path: "/authentication/register",
    component: () => import("/app/pages/authentication/register.vue")
  },
  {
    name: "authentication-space-claim-ownership",
    path: "/authentication/space-claim-ownership",
    component: () => import("/app/pages/authentication/space-claim-ownership.vue")
  },
  {
    name: "blog-article",
    path: "/blog/article",
    component: () => import("/app/pages/blog/article.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "dashboard-favorites",
    path: "/dashboard/favorites",
    component: () => import("/app/pages/dashboard/favorites.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    component: () => import("/app/pages/dashboard/quotes.vue")
  },
  {
    name: "dashboard-reviews",
    path: "/dashboard/reviews",
    component: () => import("/app/pages/dashboard/reviews.vue")
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    component: () => import("/app/pages/dashboard/settings.vue")
  },
  {
    name: "dashboard-spaces-new",
    path: "/dashboard/spaces/new",
    component: () => import("/app/pages/dashboard/spaces/new.vue")
  },
  {
    name: "site-contact",
    path: "/site/contact",
    component: () => import("/app/pages/site/contact.vue")
  },
  {
    name: "site-fbdatadeletion",
    path: "/site/fbdatadeletion",
    component: () => import("/app/pages/site/fbdatadeletion.vue")
  },
  {
    name: "site",
    path: "/site",
    component: () => import("/app/pages/site/index.vue")
  },
  {
    name: "site-landings-owner-landing",
    path: "/site/landings/owner-landing",
    component: () => import("/app/pages/site/landings/owner-landing.vue")
  },
  {
    name: "site-privacy",
    path: "/site/privacy",
    component: () => import("/app/pages/site/privacy.vue")
  },
  {
    name: "site-spaces-details",
    path: "/site/spaces/details",
    component: () => import("/app/pages/site/spaces/details.vue")
  },
  {
    name: "site-spaces-search",
    path: "/site/spaces/search",
    component: () => import("/app/pages/site/spaces/search.vue")
  }
]