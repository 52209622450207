import LoginOrigin from '~/models/enums/LoginOrigin'
import type { GoogleProfile } from '~/models/GoogleProfile'

class GoogleService {
  googleOptions: any = {
    client_id:
      '1080956121538-ko0hgj5siunhio24a7oj7tk36r6oekui.apps.googleusercontent.com',
  }

  async getUserProfile(): Promise<GoogleProfile> {
    // @ts-ignore
    await new Promise((resolve) => window.gapi.load('auth2', resolve))
    // @ts-ignore
    await window.gapi.auth2.init(this.googleOptions)
    // @ts-ignore
    const authInstance = await window.gapi.auth2.getAuthInstance()
    await authInstance.signIn()

    const googleUser = authInstance.currentUser.get()
    const basicProfile = googleUser.getBasicProfile()
    const authResponse = googleUser.getAuthResponse()
    return {
      name: basicProfile.getName(),
      username: basicProfile.getEmail(),
      socialProvidedToken: authResponse.id_token,
      origin: LoginOrigin.GOOGLE,
    }
  }
}

export default new GoogleService()
