import { jwtDecode } from 'jwt-decode'
import type { User } from '~/models/User'

export function useTokenHelper() {
  const getUserFromToken = (token: string): Partial<User> => {
    const decodedToken: any = jwtDecode(token)
    return {
      id: decodedToken.id,
      name: decodedToken.name,
      role: decodedToken.role,
      username: decodedToken.sub,
    }
  }

  return {
    getUserFromToken,
  }
}
