import type { FacebookProfile } from '~/models/FacebookProfile'
import LoginOrigin from '~/models/enums/LoginOrigin'

class FacebookService {
  facebookEndPoint = '/me'
  facebookScope: any = { scope: 'public_profile, email' }
  facebookDesiredFields: any = { fields: 'name,email,hometown,birthday' }

  async getUserProfile(): Promise<FacebookProfile> {
    // @ts-ignore
    return await new Promise((resolve) =>
      // @ts-ignore
      window.FB.login((response) => {
        if (response && response.status === 'connected') {
          // @ts-ignore
          window.FB.api(
            this.facebookEndPoint,
            this.facebookDesiredFields,
            (fbUser: any) => {
              fbUser.socialProvidedToken = response.authResponse.accessToken
              fbUser.origin = LoginOrigin.FACEBOOK
              fbUser.username = fbUser.email
              resolve(fbUser)
            }
          )
        } else {
          // @ts-ignore
          resolve(null)
        }
      }, this.facebookScope)
    )
  }
}

export default new FacebookService()
