import {
  BanknotesIcon,
  BuildingStorefrontIcon,
  ChartBarSquareIcon,
  ClockIcon,
  Cog6ToothIcon,
  HeartIcon,
  StarIcon,
} from '@heroicons/vue/24/outline'
import UserRole from '~/models/enums/UserRole'
import type { NavigationMenuItem } from '~/models/NavigationMenuItem'

export default class DashboardMenuService {
  private static menuItens: NavigationMenuItem[] = [
    {
      name: 'Inicio',
      slug: 'inicio',
      href: '/minha-conta',
      icon: ChartBarSquareIcon,
      priority: 0,
      current: true,
      userRoles: [
        UserRole.USER,
        UserRole.OWNER,
        UserRole.PROVIDER,
        UserRole.ADMIN,
      ],
    },
    {
      name: 'Meu Espaço',
      slug: 'meu-espaco',
      href: '/minha-conta/meu-espaco',
      icon: BuildingStorefrontIcon,
      priority: 1,
      current: false,
      userRoles: [UserRole.OWNER],
    },
    {
      name: 'Favoritos',
      slug: 'favoritos',
      href: '/minha-conta/favoritos',
      icon: HeartIcon,
      priority: 1.1,
      current: false,
      userRoles: [UserRole.USER],
    },
    {
      name: 'Avaliações',
      slug: 'avaliacoes',
      href: '/minha-conta/avaliacoes',
      icon: StarIcon,
      priority: 2,
      current: false,
      userRoles: [UserRole.USER, UserRole.OWNER],
    },
    {
      name: 'Orçamentos',
      slug: 'orcamentos',
      href: '/minha-conta/orcamentos',
      icon: BanknotesIcon,
      priority: 3,
      current: false,
      userRoles: [UserRole.USER, UserRole.OWNER],
    },
    {
      name: 'Espaços Pendentes',
      slug: 'espacos-pendentes',
      href: '/minha-conta/espacos/pendentes',
      icon: ClockIcon,
      priority: 4,
      current: false,
      userRoles: [UserRole.ADMIN],
    },
    {
      name: 'Configurações',
      slug: 'configuracoes',
      href: '/minha-conta/configuracoes',
      icon: Cog6ToothIcon,
      priority: 4.1,
      current: false,
      userRoles: [UserRole.OWNER, UserRole.USER, UserRole.PROVIDER],
    },
  ]

  public static getStaticMenuItens(userRole: UserRole): NavigationMenuItem[] {
    return this.menuItens
      .filter((item) => item.userRoles.includes(userRole))
      .sort((a, b) => a.priority - b.priority)
  }
}
