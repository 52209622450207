<script setup lang="ts"></script>

<template>
  <NuxtLink
    to="/"
    class="-m-1.5 p-1.5">
    <span class="sr-only">Emfesta Brasil</span>
    <img
      class="h-9 w-auto"
      src="/img/emfesta-logo.png"
      alt="Emfesta logotipo" />
  </NuxtLink>
</template>
