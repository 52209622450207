const FacebookSDK = {}

FacebookSDK.install = function install(Vue, options) {
  ;(function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    const js = d.createElement(s)
    js.id = id
    js.src = options.sdkUrl
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')

  window.fbAsyncInit = function onSDKInit() {
    const FB = window.FB
    FB.init(options)
    FB.AppEvents.logPageView()
    Vue.FB = FB
    window.dispatchEvent(new Event('fb-sdk-ready'))
  }
  Vue.FB = undefined
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(FacebookSDK, {
    appId: '1631968086955403', // process.env.VUE_APP_FACEBOOK_APP_ID,
    token: '6659e6985775d7b70a4283a796868324',
    cookie: true, // process.env.VUE_APP_FACEBOOK_APP_COOKIE,
    xfbml: true, // process.env.VUE_APP_FACEBOOK_APP_XFBML,
    version: 'v15.0', // process.env.VUE_APP_FACEBOOK_API_VERSION,
    sdkUrl: 'https://connect.facebook.net/en_US/sdk.js', // process.env.VUE_APP_FACEBBOK_SDK_URL,
  })
})
