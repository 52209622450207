<script setup lang="ts">
  import {
    ArrowLeftStartOnRectangleIcon,
    UserCircleIcon,
  } from '@heroicons/vue/24/outline'
  import type { User } from '~/models/User'
  import AuthenticationService from '~/services/AuthenticationService'
  import DashboardMenuService from '~/services/DashboardMenuService'
  import UserRole from '~/models/enums/UserRole'

  const loggedUser: Partial<User> = AuthenticationService.getLoggedUser()

  const navigation = reactive(
    DashboardMenuService.getStaticMenuItens(loggedUser.role ?? UserRole.USER)
  )

  const logout = () => {
    AuthenticationService.logout()
  }
</script>
<template>
  <div class="flex h-screen flex-col justify-between">
    <div class="-mt-8 flex justify-center">
      <ul>
        <li
          v-for="item in navigation"
          :key="item.name"
          class="my-6 text-center">
          <NuxtLink
            :to="item.href"
            class="text-sm font-semibold text-gray-700"
            ><component
              :is="item.icon"
              class="mr-3 inline-block h-6 w-6 stroke-1"
              aria-hidden="true" /><span class="font-light">{{
              item.name
            }}</span></NuxtLink
          >
        </li>
        <a
          class="block w-full cursor-pointer px-4 text-center text-sm font-light text-red-500"
          @click.prevent="logout"
          ><ArrowLeftStartOnRectangleIcon
            class="mr-1 inline-block h-6 w-6 stroke-1"
            aria-hidden="true" /><span>Sair</span></a
        >
      </ul>
    </div>
    <div class="-mt-8 mb-4 flex flex-col items-center justify-center py-4">
      <button
        class="mb-2 inline-flex rounded-full border border-gray-300 bg-white">
        <UserCircleIcon
          class="h-8 w-8 stroke-1"
          aria-hidden="true" />
      </button>
      <p class="text-center text-sm font-semibold">
        {{ loggedUser.name }}
        <span class="text-xs font-medium uppercase tracking-wide text-green-700"
          >Plano Gratuito</span
        >
      </p>
      <p
        v-if="loggedUser.role === UserRole.OWNER"
        class="cursor-pointer text-center">
        <a class="text-xs font-light hover:text-orange-400 hover:underline"
          >Ver meu espaço</a
        >
      </p>
    </div>
  </div>
</template>
